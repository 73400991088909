<template>
    <div class="line_station">
        <div class="type_1" v-bind:class="{'padding_right':arrow}" v-bind:style="boxStyle" v-if="mode==0">
            <div class="station_name" v-if="text">
                <span v-text="text" :title="text"></span>
            </div>
            <div class="line"></div>
            <div class="point" v-if="point"></div>
            <div class="arrow" v-if="arrow"></div>
            <bus v-if="bus.length" :percen="bus[0].percen" :text="busNo(bus)" :arriveTime="bus"></bus>
        </div>
        <div class="type_2" v-bind:class="{'padding_left':arrow ,'arrow_up':arrowUp}" v-bind:style="boxStyle" v-else-if="mode==1">
            <div class="station_name" v-if="text">
                <span v-text="text" :title="text"></span>
            </div>
            <div class="line"></div>
            <div class="point" v-if="point"></div>
            <div class="arrow" v-if="arrow"></div>
            <bus v-if="bus.length" :percen="bus[0].percen" :text="busNo(bus)" :mode="1" :arriveTime="bus"></bus>
        </div>
    </div>
</template>
<script type="text/javascript">
import bus from './bus'
export default {
    props: {
        mode: {
            type: [Number, String],
            default: 0
        },
        text: {
            type: String,
            default: ''
        },
        arrow: {
            type: Boolean,
            default: false
        },
        arrowUp: {
            type: Boolean,
            default: false
        },
        point: {
            type: Boolean,
            default: true
        },
        height: {
            type: [String, Number],
            default: -1
        },
        width: {
            type: [String, Number],
            default: -1
        },
        // bus: {
        //   type: Object,
        //   default: function() {
        //     return null
        //   }
        // }
        bus: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data() {
        return {
            boxStyle: {}
        }
    },
    components: {
        bus
    },
    watch: {
        height: function(val) {
            if (val !== -1) {
                this.setBoxStyle()
            }
        },
        width: function(val) {
            if (val !== -1) {
                this.setBoxStyle()
            }
        }
    },
    created() {
        this.setBoxStyle()
    },
    methods: {
        busNo(arr) {
            let non = ''
            arr.forEach(item => {
                non += item.vehicleNo + '<br>'
            })
            // non = '闽CYF110'

            return non
        },
        setBoxStyle() {
            let obj = {}
            if (this.height !== -1) {
                let h = String(this.height).replace('px', '') + 'px'
                obj.height = h
            } else {
                obj.height = '150px'
            }
            if (this.width !== -1) {
                let h = String(this.width).replace('px', '') + 'px'
                obj.width = h
            } else {
                obj.width = '50px'
            }
            this.boxStyle = obj
        }
    }
}
</script>
<style type="text/css" lang="scss" scoped="line_station">
$up_color: #ff5599;
$down_color: #66dd99;

.line_station {
    display: inline-block;

    .type_2,
    .type_1 {
        position: relative;
        height: 150px;
        width: 50px;

        .station_name {
            writing-mode: vertical-lr;
            text-align: left;
            letter-spacing: 1px;
            height: 80px;
            word-break: keep-all;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
        }

        .line {
            position: absolute;
            left: 0px;
            right: 0px;
            height: 4px;
        }

        .point {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
        }

        .arrow {
            width: 0;
            height: 0;
            border: solid transparent;
            position: absolute;
            border-top-width: 8px;
            border-left-width: 18px;
            border-right-width: 18px;
            border-bottom-width: 8px;
        }

        &.padding_right {
            margin-right: 36px;
        }

        &.padding_left {
            margin-left: 36px;
        }

        &.type_1 {
            .station_name {
                top: 0;
                right: 0;
            }

            .line {
                bottom: 4px;
                background: $up_color;
            }

            .point {
                right: 2px;
                bottom: 1px;
                background: $up_color;
            }

            .arrow {
                border-bottom-color: $up_color;
                border-left-color: $up_color;
                right: -36px;
                bottom: 4px;
            }
        }

        &.type_2 {
            .station_name {
                bottom: 0;
                left: 0;
            }

            .line {
                top: 4px;
                background: $down_color;
            }

            .point {
                left: 2px;
                top: 1px;
                background: $down_color;
            }

            .arrow {
                border-top-color: $down_color;
                border-right-color: $down_color;
                left: -36px;
                top: 4px;
            }

            &.arrow_up {
                .line {
                    bottom: 4px;
                    top: auto;
                }

                .arrow {
                    top: auto;
                    bottom: 4px;
                    border-top-color: transparent;
                    border-right-color: $down_color;
                    border-bottom-color: $down_color;
                    border-left-color: transparent;
                }
            }
        }
    }
}
</style>

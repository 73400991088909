<template>
    <div class="line_map">
        <div class="mask" v-if="isBigMap"></div>
        <div class="map_fixed_box" :class="{'big_map':isBigMap}">
            <span class="i_span" v-on:click="toggleSize"><i :class="isBigMap?'el-icon-zoom-out':'el-icon-zoom-in'"></i></span>
            <div id="line_map_box"></div>
        </div>
    </div>
</template>
<script type="text/javascript">
export default {
    props: {
        stations: {
            type: Array,
            default () {
                return []
            }
        },
        busList: {
            type: Array,
            default () {
                return []
            }
        },
        routeId: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            isBigMap:false,
            map: null,
            interval: null,
            stationList: [],
            centerPoint: new BMap.Point(118.67983, 24.882193),
        }
    },
    watch: {
        stations: function(val) {
            let list = val.filter(one => one.goType == 1)
            this.stationList = list
            this.setCenterPoint(list)
            if (this.map) {
                this.setMarker()
            }
        },
        busList: function() {
            if (this.map) {
                this.setMarker()
            }
        },
    },
    methods: {
        init() {
            if (!this.map) {
                this.loadScript()
            }
        },
        toggleSize(){
            this.isBigMap = !this.isBigMap
        },
        setCenterPoint(list) {
            console.log(list.length)
            let lat = 0,
                lng = 0;
            list.forEach(one => {
                lat += Number(one.lat)
                lng += Number(one.lng)
                console.log(one.lat)
            })
            let leng = list.length
            console.log(lat / leng)
            this.centerPoint = new BMap.Point(lng / leng, lat / leng)
            this.map && this.map.centerAndZoom(this.centerPoint, 14)
        },
        loadScript() {
            // var _this = this
            let interval = setInterval(() => {
                if (window.BMap) {
                    clearInterval(interval)
                    this.map = new window.BMap.Map('line_map_box')
                    this.map.centerAndZoom(this.centerPoint, 10)

                    var ctrl = new BMapLib.TrafficControl({
                        showPanel: false //是否显示路况提示面板
                    });
                    this.map.addControl(ctrl);
                    ctrl.setAnchor(BMAP_ANCHOR_BOTTOM_RIGHT);

                    // var ipLocal = function() {
                    //     var myCity = new BMap.LocalCity()
                    //     myCity.get(result => {
                    //         var cityName = result.name
                    //         _this.map.setCenter(cityName)
                    //         let center = result.center
                    //         _this.map.centerAndZoom(
                    //             new BMap.Point(center.lng, center.lat),
                    //             11
                    //         )
                    //     })
                    // }
                    // ipLocal()
                    // var geolocation = new BMap.Geolocation();
                    // // 开启SDK辅助定位
                    // geolocation.enableSDKLocation();
                    // geolocation.getCurrentPosition(function(r) {
                    //     if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                    //         var mk = new BMap.Marker(r.point);
                    //         _this.map.addOverlay(mk);
                    //         _this.map.panTo(r.point);
                    //     } else {
                    //         ipLocal();
                    //     }
                    // });
                    this.setCenterPoint(this.stationList)
                    this.map.enableScrollWheelZoom(true)
                    this.setMarker()
                }
            }, 1000)
        },
        setMarker(list) {
            this.map.clearOverlays()
            list = list ? list : this.stationList.concat(this.busList)
            console.log(list)
            list.forEach(item => {
                let point = new window.BMap.Point(item.lng, item.lat)
                let iconUrl = 'https://developer.baidu.com/map/jsdemo/img/car.png'
                let icon = new BMap.Icon(iconUrl, new BMap.Size(40, 40))
                let marker = new BMap.Marker(point)
                let labelText = item.name
                if (item.vehicleId) {
                    marker = (point, { icon: icon })
                    marker.addEventListener('mouseover', () => {
                        var opts = {
                            width: 250, // 信息窗口宽度    
                            height: 100, // 信息窗口高度    
                            title: '车辆信息' // 信息窗口标题   
                        }
                        let dome = `<div>车牌：${item.vehicleNo}<br/>到站时间：${item.arriveTime}</div>`
                        let infoWindow = new BMap.InfoWindow(dome, opts);
                        this.map.openInfoWindow(infoWindow, point)
                    })

                    labelText = item.vehicleNo
                }
                this.map.addOverlay(marker)

                // 文字标注
                let label = new BMap.Label(labelText, {
                    offset: new BMap.Size(30, -11)
                })
                if (item.vehicleId) {
                    label = new BMap.Label(labelText, {
                        offset: new BMap.Size(-20, -20)
                    })
                    label.setStyle({
                        color: '#fbfbfb',
                        'background-color': '#3a3bc2',
                        'border-radius': '10px',
                        border: '1px solid #3a3bc2'
                    })
                }
                marker.setLabel(label)
            })
        }
    }
}

</script>
<style type="text/css" lang="scss" scoped="line_map">
.line_map {
    height: 100%;
    width: 100%;

    .map_fixed_box {
        height: 100%;
        width: 100%;
        position:relative;
        &.big_map{
            position:fixed;
            top:80px;
            bottom:20px;
            left:38px;
            right:38px;
            z-index: 3100;
            width: auto;
            height: auto;
        }
        .i_span{
            display: inline-block;
            background-color:rgba(0,0,0,.5);
            color:#fff;
            padding: 8px;
            cursor: pointer;
            position:absolute;
            right:0;
            top:0;
            z-index: 100;
            i{
                font-size: 24px;
            }
        }
        #line_map_box {
            height: 100%;
            width: 100%;
        }
    }
    .mask{
        position:fixed;
        left:0;
        right: 0;
        bottom: 0;
        top:0;
        background-color:rgba(0,0,0,.3);
        z-index: 2999;
    }
}

</style>

<template>
    <div class="fixed_line">
        <jy-table ref="multipleTable" :data="list" tooltip-effect="dark" header-cell-class-name="bg_color" border style="width: 100%">
            <jy-table-column type="index" width="60" label="序号" fixed="left"></jy-table-column>
            <jy-table-column prop="routeName" label="所属线路" min-width="100" fixed="left"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="180" fixed="left"></jy-table-column>
            <jy-table-column prop="rentRouteName" label="定点专线名称" min-width="100" fixed="left"></jy-table-column>
            <jy-table-column prop="operateDate" label="营运日期" min-width="100"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号" min-width="100"></jy-table-column>
            <jy-table-column prop="driverName" label="司机" min-width="100"></jy-table-column>
            <jy-table-column prop="totalMileage" label="总里程(km)" min-width="100"></jy-table-column>
            <jy-table-column prop="runMileage" label="运行里程(km)" min-width="100"></jy-table-column>
            <jy-table-column prop="deviceIds" label="设备ID" min-width="100"></jy-table-column>
            <jy-table-column prop="shiftConversion" label="班次折算" min-width="100"></jy-table-column>
            <jy-table-column prop="operateStateStr" label="运行情况" min-width="100"></jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="200"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
    </div>
</template>
<script type="text/javascript">
export default {
    props: {
        formData: {
            type: Object,
            default: function() {
                return {}
            },
        },
    },
    data() {
        return {
            pageIndex: 1,
            total: 10,
            pageSize: 10,
            list: [],
        }
    },
    created() {
        // this.getList()
    },
    activated() {
        // this.getList()
    },
    methods: {
        getList() {
            let formData = this.formData
            let url = '/waybillRentSettle/getList'
            let params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                ...formData,
            }
            this.$http.post(url, params).then(({ detail }) => {
                this.total = detail.total
                detail.list.forEach(item => {
                    switch (item.operateState) {
                        case '0':
                            item.operateStateStr = '未发车'
                            break
                        case '1':
                            item.operateStateStr = '已发车'
                            break
                        case '2':
                            item.operateStateStr = '已完成'
                            break
                    }
                })
                this.list = detail.list
            })
        },
        handleSizeChange(e) {
            this.pageSize = e
            this.getList()
        },
        handleCurrentChange(e) {
            this.pageIndex = e
            this.getList()
        },
    },
}
</script>

<template>
    <div class="line_condition_top" v-empty="isEmpty">
        <div>
            <div v-for="(one,k) in list" v-bind:key="k" v-bind:class="'top_block_'+k">
                <template v-if="one.routeName">
                    <span class="sb_n" v-text="one.routeName"></span>
                    <span class="sb_n sb_position" v-bind:class="{'shadow':scrollLeft}" v-text="one.routeName"></span>
                </template>
                <template v-else-if="one.column">
                    <div class="item_column" v-for="(item,j) in one.column" v-bind:key="String(k)+String(j)">
                        <template v-if="item.rows">
                            <div class="item_column_row" v-for="row in item.rows" v-bind:key="row.name">
                                <div class="row_name" v-text="row.rowName" v-if="row.rowName"></div>
                                <div class="item_column_item_row" v-for="itemRow in row.rowValue" v-bind:key="itemRow.rowName">
                                    <div class="name" v-text="itemRow.name"></div>
                                    <div class="value" v-text="itemRow.value"></div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="name" v-text="item.name"></div>
                            <div class="value" v-text="item.value"></div>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
export default {
    props: {
        list: {
            type: Array,
            default: function() {
                return [{
                        lineNumer: 29
                    },
                    {
                        column: [{
                                name: '总车辆',
                                value: 20
                            },
                            {
                                name: '营运',
                                value: 12
                            },
                            {
                                name: '非营运',
                                value: 5
                            },
                            {
                                name: '专线',
                                value: 1
                            },
                            {
                                name: '不在线',
                                value: 4
                            },
                            {
                                rows: [{
                                        rowName: '上行',
                                        rowValue: [{
                                                name: '运行',
                                                value: 9
                                            },
                                            {
                                                name: '待发',
                                                value: 2
                                            },
                                            {
                                                name: '异常',
                                                value: 0
                                            }
                                        ]
                                    },
                                    {
                                        rowName: '上行',
                                        rowValue: [{
                                                name: '运行',
                                                value: 9
                                            },
                                            {
                                                name: '待发',
                                                value: 2
                                            },
                                            {
                                                name: '异常',
                                                value: 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        column: [{
                                name: '总车辆',
                                value: 20
                            },
                            {
                                name: '营运',
                                value: 12
                            },
                            {
                                name: '非营运',
                                value: 5
                            },
                            {
                                name: '专线',
                                value: 1
                            },
                            {
                                name: '不在线',
                                value: 4
                            },
                            {
                                rows: [{
                                        rowName: '上行',
                                        rowValue: [{
                                                name: '运行',
                                                value: 120
                                            },
                                            {
                                                name: '待发',
                                                value: 2
                                            },
                                            {
                                                name: '异常',
                                                value: 0
                                            }
                                        ]
                                    },
                                    {
                                        rowName: '上行',
                                        rowValue: [{
                                                name: '运行',
                                                value: 9
                                            },
                                            {
                                                name: '待发',
                                                value: 2
                                            },
                                            {
                                                name: '异常',
                                                value: 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        column: [{
                                name: '平均速度',
                                value: 24.4
                            },
                            {
                                rows: [{
                                        rowName: '',
                                        rowValue: [{
                                            name: '上行',
                                            value: 25.1
                                        }]
                                    },
                                    {
                                        rowName: '',
                                        rowValue: [{
                                            name: '上行',
                                            value: 23.6
                                        }]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    },
    data() {
        return {
            scrollLeft: 0
        }
    },
    computed: {
        isEmpty:function(){
            return (!this.list || !this.list.length)?'暂无运营数据':false
        }
    },
    activated() {
        this.scrollFun()
    },
    methods: {
        scrollFun() {
            this.$nextTick(() => {
                $('.line_condition').scroll(e => {
                    this.scrollLeft = e.target.scrollLeft
                })
            })
        }
    }
}

</script>
<style type="text/css" lang="scss" scoped="line_condition_top">
$item_padding_lr: 8px;
$t_b_distance: 4px;

.line_condition_top {
    height: 66px;

    >div {
        display: flex;
        align-items: center;

        [class^='top_block_'] {
            padding: 10px 10px;
            white-space: nowrap;

            +[class^='top_block_'] {
                border-left: 1px solid $bordercolor;
            }

            .sb_n {
                font-size: 20px;
                padding: 0 20px;
                display: inline-block;

                &.sb_position {
                    position: absolute;
                    top: 0;
                    left: 0;
                    text-align: center;
                    padding: 0 30px;
                    line-height: 66px;
                    background: #fff;
                    border-right: 1px solid $bordercolor;

                    &.shadow {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            width: 2px;
                            background: transparent;
                            box-shadow: 3px 0 4px #ccc;
                        }
                    }
                }
            }

            .item_column {
                display: inline-block;
                text-align: center;
                padding: 0 $item_padding_lr;
                line-height: 20px;

                .name {
                    font-size: 13px;
                }

                >.value {
                    margin-top: $t_b_distance;
                }

                .value {
                    font-size: 13px;
                    color: #ff5599;
                }

                .item_column_row {
                    +.item_column_row {
                        margin-top: $t_b_distance;
                    }

                    .row_name {
                        display: inline-block;
                        padding-right: $item_padding_lr;
                        border-right: 1px solid $bordercolor;
                    }

                    .item_column_item_row {
                        display: inline-block;
                        padding-left: $item_padding_lr;

                        .name,
                        .value {
                            display: inline-block;

                            &.value {
                                padding-left: 6px;
                                width: 34px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>

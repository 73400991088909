<template>
    <div class="line_t" v-bind:class="{'hidden_main':!isShow,'top_line':type==0}">
        <div class="line_condition" v-bind:class="{'hidden':!isShow}">
            <div class="box line_main_box" v-show="type==0">
                <div class="top">
                    <top v-bind:list="topBlockMsg"></top>
                </div>
                <div class="line">
                    <div class="center line_box">
                        <div class="center">
                            <station-line :routeId="routeId" @firstStaName="firstStaName" @updateBusList="updateBusList" @updateStationList="updateStationList" ref="stationLine"></station-line>
                        </div>
                        <div class="station_name left_name">
                            <span v-text="upText"></span>
                        </div>
                        <div class="station_name right_name">
                            <span v-text="downText"></span>
                        </div>
                    </div>
                    <div class="left">
                        <p>上行待发</p>
                        <station height="20" arrow mode="0" width="60" :point="false" class="station_arrow"></station>
                        <div class="table_box">
                            <el-table :data="leftList" size="small">
                                <el-table-column prop="actualVehicleNo" label="车牌" width="68"></el-table-column>
                                <el-table-column prop="arrivalTime" label="到达" width="44"></el-table-column>
                                <el-table-column prop="planDepartureTime" label="发车" width="44"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="right">
                        <p>下行待发</p>
                        <station height="20" arrow arrowUp mode="1" width="60" :point="false" class="station_arrow"></station>
                        <div class="table_box">
                            <el-table :data="rightList" size="small">
                                <el-table-column prop="actualVehicleNo" label="车牌" width="68"></el-table-column>
                                <el-table-column prop="arrivalTime" label="到达" width="44"></el-table-column>
                                <el-table-column prop="planDepartureTime" label="发车" width="44"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box" v-show="type==1">
                <my-map :busList="busList" :stations="allStationList" :routeId="routeId" ref="myMap"></my-map>
            </div>
        </div>
        <div class="line_t_foot">
            <div v-on:click="setType(0)">
                <span>运行图</span>
                <i class="jy-jiantou"></i>
            </div>
            <div v-on:click="setIsShow()">
                <span v-text="handlerText"></span>
                <i class="el-icon-arrow-up"></i>
            </div>
            <div v-on:click="setType(1)">
                <span>实况图</span>
                <i class="jy-quxian"></i>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import top from './top.vue'
import stationLine from './line.vue'
import station from './station'
import map from './map'
export default {
    props: {
        routeId: {
            type: String,
            default: ''
        },
        routeName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            topBlockMsg: [],
            isShow: true,
            type: 0,
            departureList: [],
            conditionStyle: {},
            upText: '',
            downText: '',
            vehicleIds: [],
            interval: null,
            intervalDetail: null,

            allStationList: [],
            busList: [],
        }
    },
    watch: {
        $route: function(val) {
            if (
                val.name != 'operationalInformation' &&
                val.name != 'operationalInformationDefault'
            ) {
                clearInterval(this.interval)
                clearInterval(this.intervalDetail)
                clearInterval(this.$refs.myMap.interval)
            }
        },
        routeId: function(val) {
            if (val) {
                this.getDetail()
                clearInterval(this.intervalDetail)
                this.intervalDetail = setInterval(() => {
                    this.getDetail()
                    console.log('getDetail')
                }, 120000)
                // }, 120000)

                // this.getReadyList()
                this.getBusinfor()
            }
        },
        isShow: function(val) {
            if (val) {
                this.$refs.stationLine.setIntervalFun()
            } else {
                clearInterval(this.$refs.stationLine.interval)
            }
        }
    },
    activated() {
        this.setIntervalFun()
    },
    computed: {
        isTopEmpty: function() {
            return (!this.topBlockMsg || !this.topBlockMsg.length) ? '暂无运营数据' : false
        },
        leftList: function() {
            let list = this.departureList.filter(item => {
                return item.dir == '1'
            })
            return list
        },
        rightList: function() {
            let list = this.departureList.filter(item => {
                return item.dir == '2'
            })
            return list
        },
        handlerText: function() {
            let text = '展开'
            if (this.isShow) {
                text = '收起'
            }
            return text
        }
    },
    components: {
        top,
        'my-map': map,
        station,
        stationLine
    },
    methods: {
        setIsShow(b) {
            if (!this.isShow && !this.routeId) {
                this.$alert('请先选择路线', '提示', {
                    confirmButtonText: '确定'
                })
                return
            }
            if (b === undefined) {
                this.isShow = !this.isShow
            } else {
                this.isShow = b
            }
        },
        setType(v) {
            this.type = v
            if (this.type == 1) {
                this.$refs.myMap.init()
            }
        },
        getDetail() {
            let url = '/waybillOperate/queryRealtimePageVo'
            let params = {
                routeId: this.routeId
            }
            let option = {
                err: (err) => {
                    this.topBlockMsg=[]
                    console.log(err)
                }
            }
            this.$http.post(url, params, option).then(({ detail }) => {
                this.setTopBlockMsg(detail)
            })
        },
        setTopBlockMsg(data) {
            let list = [{
                    routeName: this.routeName
                },
                {
                    column: [{
                            name: '总车辆',
                            value: data.vehicleNum
                        },
                        {
                            name: '营运',
                            value: data.operateVehicleNum
                        },
                        {
                            name: '非营运',
                            value: data.nonOperateVehicleNum
                        },
                        {
                            name: '专线',
                            value: data.rentOperateVehicleNum
                        },
                        {
                            name: '不在线',
                            value: data.offlineNum
                        },
                        {
                            rows: [{
                                    rowName: '上行',
                                    rowValue: [{
                                            name: '运行',
                                            value: data.upVehicleNum
                                        },
                                        {
                                            name: '待发',
                                            value: data.upVehicleReadyNum
                                        },
                                        {
                                            name: '异常',
                                            value: data.upErrorNum
                                        }
                                    ]
                                },
                                {
                                    rowName: '下行',
                                    rowValue: [{
                                            name: '运行',
                                            value: data.downVehicleNum
                                        },
                                        {
                                            name: '待发',
                                            value: data.downVehicleReadyNum
                                        },
                                        {
                                            name: '异常',
                                            value: data.downErrorNum
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    column: [{
                            name: '计划数',
                            value: data.planNum
                        },
                        {
                            name: '完成',
                            value: data.planDoneNum
                        },
                        {
                            name: '未完成',
                            value: data.planUndoneNum
                        },
                        {
                            name: '砍脱班',
                            value: data.ktShiftNum
                        },
                        {
                            name: '准点率',
                            value: data.punctualityRate
                        },
                        {
                            rows: [{
                                    rowName: '上行',
                                    rowValue: [{
                                            name: '完成',
                                            value: data.shiftUpDoneNum
                                        },
                                        {
                                            name: '未完成',
                                            value: data.shiftUpUndoneNum
                                        },
                                        {
                                            name: '砍脱班',
                                            value: data.ktShiftUpNum
                                        }
                                    ]
                                },
                                {
                                    rowName: '下行',
                                    rowValue: [{
                                            name: '完成',
                                            value: data.shiftDownDoneNum
                                        },
                                        {
                                            name: '未完成',
                                            value: data.shiftDownUndoneNum
                                        },
                                        {
                                            name: '砍脱班',
                                            value: data.ktShiftDownNum
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    column: [{
                            name: '平均速度',
                            value: data.avgSpeed
                        },
                        {
                            rows: [{
                                    rowName: '',
                                    rowValue: [{
                                        name: '上行',
                                        value: data.upAvgSpeed
                                    }]
                                },
                                {
                                    rowName: '',
                                    rowValue: [{
                                        name: '下行',
                                        value: data.downAvgSpeed
                                    }]
                                }
                            ]
                        }
                    ]
                }
            ]
            this.topBlockMsg = list
        },
        // getReadyList() {
        //   let url = '/waybillOperate/queryReadyShiftVehicle'
        //   let params = {
        //     routeId: this.routeId
        //   }
        //   this.$http.post(url, params).then(({ detail }) => {
        //     detail.forEach(one => {
        //       one.readyVehicleNoStr =
        //         one.readyVehicleNo && one.readyVehicleNo.substring(2)
        //       one.arriavlTimeStr =
        //         one.arriavlTime && one.arriavlTime.substring(0, 5)
        //       one.departureTimeStr =
        //         one.departureTime && one.departureTime.substring(0, 5)
        //     })
        //     this.departureList = detail
        //   })
        // },
        setIntervalFun() {
            //   if (this.vehicleIds.length) {
            //     this.getCommitted(this.vehicleIds)
            //     clearInterval(this.interval)
            //     this.interval = setInterval(() => {
            //       this.getCommitted(this.vehicleIds)
            //       console.log(3)
            //     }, 300000)
            //   }
            if (this.routeId) {
                this.getDetail()
                clearInterval(this.intervalDetail)
                this.intervalDetail = setInterval(() => {
                    this.getDetail()
                    console.log('getDetail')
                }, 120000)

                this.getCommitted(this.routeId)
                clearInterval(this.interval)
                this.interval = setInterval(() => {
                    this.getCommitted(this.routeId)
                    console.log('getCommitted')
                }, 300000)
            }
        },
        getBusinfor() {
            if (this.routeId) {
                let url = '/waybillOperate/queryRealtimeVehicleList'
                let option = {
                    routeId: this.routeId
                }
                this.$http.post(url, option).then(({ detail }) => {
                    let b = detail
                    //   console.log(b)
                    let vehicleIds = []
                    b.forEach(item => {
                        vehicleIds.push(item.actualVehicleId)
                    })
                    this.vehicleIds = vehicleIds
                    this.getCommitted(this.routeId)
                    clearInterval(this.interval)
                    this.interval = setInterval(() => {
                        this.getCommitted(this.routeId)
                        console.log('getCommitted')
                    }, 300000)
                })
            }
        },
        getCommitted(routeId) {
            let url = '/waybillOperate/queryReadyShiftVehicle'
            let params = {
                routeId: routeId
            }
            let option = {
                err: (err) => {
                    console.log(err)
                }
            }
            this.$http.post(url, params, option).then(({ detail }) => {
                this.departureList = detail
            })
        },
        setStyle() {
            let obj = {}
            this.$nextTick(() => {
                let w = $(this.$el).width()
                obj = {
                    width: w + 'px'
                }
                this.conditionStyle = obj
            })
        },
        firstStaName(upText, downText) {
            this.upText = upText
            this.downText = downText
        },
        updateStationList(list){
            this.allStationList = list
        },
        updateBusList(list){
            this.busList = list
        }
    }
}

</script>
<style type="text/css" lang="scss" scoped="line_condition">
$anima_time: 0.5s;
// $side_wdtth: 166px;
$side_wdtth: 193px;
$top_height: 68px;
$box_height: 320px;

.line_border{
    content: '';
    display: block;
    position: absolute;
    top: $top_height - 1px;
    left: 0;
    right: 0;
    height: 1px;
    background: transparent;
    transition: all $anima_time;
    z-index: 100;
}

.line_t {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 20px;
    border: 1px solid $bordercolor;

    &:before {
        @extend .line_border;
    }
    &.top_line{
        &:before {
            @extend .line_border;
            background:$bordercolor;
        }
    }

    .line_condition {
        font-size: 14px;
        overflow-x: scroll;
        overflow-y: hidden;
        transition: all $anima_time;
        height: 411px;
        border-bottom: 1px solid $bordercolor;

        .box {
            border-bottom: 1px solid $bordercolor;
            height: 100%;

            &.line_main_box {
                min-width: 1380px;

                .line {
                    width: auto;
                    // padding: 0 $side_wdtth+44px;

                    .left,
                    .right {
                        width: $side_wdtth;
                        position: absolute;
                        top: $top_height;
                        background: #fff;
                        padding: 20px 10px;
                        height: $box_height;

                        &.left {
                            left: 0;
                        }

                        &.right {
                            right: 0;
                            text-align: right;
                        }
                    }

                    .station_arrow {
                        margin-top: -10px;
                    }

                    .line_box {
                        .line_msg {
                            padding: 20px $side_wdtth + 40px+20px;
                        }

                        .station_name {
                            position: absolute;
                            top: $top_height;
                            background: #fff;
                            writing-mode: vertical-lr;
                            height: $box_height;
                            width: 40px;
                            text-align: center;
                            line-height: 40px;

                            &.left_name {
                                left: $side_wdtth;
                                border-left: 1px solid $bordercolor;
                                border-right: 1px solid $bordercolor;
                            }

                            &.right_name {
                                right: $side_wdtth;
                                border-left: 1px solid $bordercolor;
                                border-right: 1px solid $bordercolor;
                            }
                        }
                    }
                }
            }
        }

        +.line_t_foot {
            text-align: center;
            line-height: 22px;
            border-top: 1px solid $bordercolor;
            border-top: none;
            position: absolute;
            bottom: 0;
            width: 100%;
            background: #fff;
            display: flex;
            @extend .hand;

            >div {
                flex: 1;

                +div {
                    border-left: 1px solid $bordercolor;
                }

                &:hover {
                    i {
                        color: $buttonbackgroud;
                        // font-weight: bold;
                    }

                    span {
                        color: $buttonbackgroud;
                    }
                }
            }

            i {
                margin-left: 6px;
                display: inline-block;
            }
        }

        &.hidden {
            height: 0px;
            opacity: 0;

            +.line_t_foot {
                >div {
                    flex: 1;

                    &:nth-of-type(2) {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    &.hidden_main {
        &:before {
            opacity: 0;
        }
    }
}

</style>
<style type="text/css" lang="scss">
.line_t {
    .line_condition {

        .left,
        .right {
            overflow: auto;
            height: 100%;

            .table_box {
                .cell {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }
}

</style>

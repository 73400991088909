<template>
    <div class="line_bus" v-bind:style="boxStyle">
        <div class="type_1" v-if="mode==0">
            <el-popover placement="top-start" width="160" trigger="hover">
                <div slot="reference" class="bus_box" v-bind:class="{'arrive':percen==100}" v-bind:style="carStyle">
                    <!-- <span v-text="text"></span> -->
                    <div v-html="text"></div>
                    <i class="jy-bus"></i>
                </div>
                <template>
                    <div v-html="arrive(arriveTime)">
                    </div>
                </template>
            </el-popover>
        </div>
        <div class="type_2" v-if="mode==1">
            <el-popover placement="top-start" width="160" trigger="hover">
                <div slot="reference" class="bus_box" v-bind:class="{'arrive':percen==100}" v-bind:style="carStyle">
                    <!-- <span v-text="text"></span> -->
                    <div v-html="text"></div>
                    <i class="jy-bus"></i>
                </div>
                <template>
                    <div v-html="arrive(arriveTime)">
                    </div>
                </template>
            </el-popover>
        </div>
    </div>
</template>
<script type="text/javascript">
const smallWidth = 20
const bigWidth = 24
export default {
    props: {
        mode: {
            type: [Number, String],
            default: 0
        },
        text: {
            type: String,
            default: ''
        },
        // arriveTime: {
        //     type: String,
        //     default: ''
        // },
        arriveTime: {
            type: Array,
            default: function() {
                return []
            }
        },
        percen: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            boxStyle: {},
            carStyle: {}
        }
    },
    created() {
        this.setBoxStyle()
        this.setCarStyle()
    },
    watch: {
        percen: function() {
            this.setBoxStyle()
            this.setCarStyle()
        },
        mode: function() {
            this.setBoxStyle()
            this.setCarStyle()
        }
    },
    methods: {
        arrive(val) {
            console.log(val)
            let a = '到达时间：'
            val.forEach(item => {
                a += '<br>' + item.vehicleNo + ' : ' + item.arriveTime
            })
            return a
        },
        setBoxStyle() {
            let obj = {}
            if (this.mode == 0) {
                obj.top = '80px'
            } else if (this.mode == 1) {
                obj.top = '10px'
            }
            this.boxStyle = obj
        },
        setCarStyle() {
            let obj = {}

            let fullW
            if (this.percen == 100) {
                fullW = bigWidth
            } else {
                fullW = smallWidth
            }
            if (this.mode == 0) {
                obj.left = this.percen + '%'
                obj.marginLeft = -(fullW + 14) / 2 + 'px'
            } else {
                obj.right = this.percen + '%'
                obj.marginRight = -(fullW + 14) / 2 + 'px'
            }
            this.carStyle = obj
        }
    }
}
</script>
<style type="text/css" scoped="line_bus" lang="scss">
.line_bus {
    height: 60px;
    width: 100%;
    position: absolute;

    > div {
        height: 100%;
        position: relative;

        &.type_1 {
            .bus_box {
                position: absolute;
                bottom: 2px;
                cursor: pointer;

                div {
                    font-size: 10px;
                    display: inline-block;
                    word-break: keep-all;
                    white-space: nowrap;
                    position: absolute;
                    left: 50%;
                    margin-left: -17px;
                    // top: -14px;
                    bottom: 20px;
                }

                i {
                    font-size: 20px;
                }

                &.arrive {
                    i {
                        font-size: 24px;
                    }
                }
            }
        }

        &.type_2 {
            .bus_box {
                position: absolute;
                top: 2px;
                cursor: pointer;

                div {
                    font-size: 10px;
                    display: inline-block;
                    word-break: keep-all;
                    white-space: nowrap;
                    position: absolute;
                    left: 50%;
                    margin-left: -17px;
                    // bottom: -14px;
                    top: 20px;
                }

                i {
                    font-size: 20px;
                    display: inline-block;
                    transform: rotateY(180deg);
                }

                &.arrive {
                    i {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
</style>

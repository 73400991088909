<template>
    <div class="line_msg line_main_width" v-bind:style="lineMsgStyle" v-empty="isEmpty">
        <div class="line_up">
            <station :text="one.name" v-for="(one,k) in upList" :key="one.siteId" :width="k==0?50:sw" :arrow="k==upList.length-1" :bus="one.bus"></station>
        </div>
        <div class="line_down">
            <station mode="1" :text="one.name" v-for="(one,k) in downList" :key="one.siteId" :width="k==downList.length-1?50:sw" :arrow="k==0" :bus="one.bus"></station>
        </div>
    </div>
</template>
<script type="text/javascript">
import station from './station'
const defaultSw = 50
export default {
    props: {
        routeId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            allStationList: [],
            busList: [],
            lineMsgStyle: {},
            sw: defaultSw,
            interval: null,
            // 获取的线路所有车辆信息
            allBusInfor: []
        }
    },
    computed: {
        isEmpty: function() {
            return (!this.allStationList || !this.allStationList.length) ? '暂无数据' : false
        },
        upList: function() {
            let list = []
            list = this.allStationList.filter(item => {
                return item.goType == 1
            })
            list.sort((item1, item2) => {
                return item1.order - item2.order
            })
            return list
        },
        downList: function() {
            let list = []
            list = this.allStationList.filter(item => {
                return item.goType == 2
            })
            list.sort((item1, item2) => {
                return item2.order - item1.order
            })
            return list
        }
    },
    components: {
        station
    },
    watch: {
        $route: function(val) {
            if (
                val.name != 'operationalInformation' &&
                val.name != 'operationalInformationDefault'
            ) {
                clearInterval(this.interval)
            }
        },
        routeId: function(val) {
            if (val) {
                this.getBusinfor() //获取线路拥有的车辆信息
                this.getAllStations()
            }
        },
        upList: function() {
            this.firstSta()
        },
        downList: function() {
            this.firstSta()
        }
    },
    created() {
        // this.setStyle();
    },
    activated() {
        this.setIntervalFun()
    },
    methods: {
        getBusinfor() {
            if (this.routeId) {
                let url = '/waybillOperate/queryRealtimeVehicleList'
                let params = {
                    routeId: this.routeId
                }
                let option = {
                    err: (err) => {
                        console.log(err)
                    }
                }
                this.$http.post(url, params, option).then(({ detail }) => {
                    let b = detail
                    // console.log(b)
                    let l = []
                    b.forEach(item => {
                        l.push({
                            actualVehicleId: item.actualVehicleId,
                            actualVehicleNo: item.actualVehicleNo
                        })
                    })
                    this.allBusInfor = l
                    // console.log(this.allBusInfor)
                })
            }
        },
        setIntervalFun() {
            if (this.allStationList.length && this.routeId) {
                clearInterval(this.interval)
                this.getAllBus()
                this.interval = setInterval(() => {
                    this.getAllBus()
                }, 30000)
            }
        },
        getAllStations() {
            let url = '/baseinfosite/qiueryAllSitesByRouteId'
            // if (this.global.isDev) {
            //  url = '/baseinfosite/queryStopPosition';
            // }
            let params = {
                routeId: this.routeId
            }
            let option = {
                err: (err) => {
                    console.log(err)
                }
            }
            this.$http.post(url, params, option).then(({ detail }) => {
                this.allStationList = detail
                this.getAllBus()
                this.setMainWidth()
                if (this.$parent.isShow) {
                    this.setIntervalFun()
                }

                this.$emit('updateStationList',this.allStationList)
                // if (this.global.isDev) {
                //  this.$util.setStor('stationList', detail);
                // }
            })
        },
        setStyle() {
            let obj = {}
            this.$nextTick(() => {
                let w = $(this.$el).width()
                obj = {
                    width: w + 'px'
                }
                this.lineMsgStyle = obj
            })
        },
        setSw() {
            var uL = this.upList.length
            var dL = this.downList.length
            var mL = uL > dL ? uL : dL
            var mw = defaultSw * mL
            var centerW
            this.$nextTick(() => {
                centerW = $(this.$el)
                    .parent('.center')
                    .width()
                var L = centerW - mw - 226 * 2 - 50
                if (L > 0) {
                    this.sw = defaultSw + L / (mL - 1)
                } else {
                    this.sw = defaultSw
                }
            })
        },
        setMainWidth() {
            this.setSw()
            this.$nextTick(() => {
                let uL = this.upList.length
                let dL = this.downList.length
                let mL = uL > dL ? uL : dL
                let mw = this.sw * (mL - 1) + 50
                $('.line_main_width').width(mw + 40)
            })
        },
        getAllBus() {
            let vehicleIds = []
            this.allBusInfor.forEach(item => {
                vehicleIds.push(item.actualVehicleId)
            })
            let url = '/waybillOperate/queryVehiclePosition'
            let params = {
                routeId: this.routeId,
                vehicleIds
            }
            let option = {
                err: (err) => {
                    console.log(err)
                }
            }
            this.$http.post(url, params, option).then(({ detail }) => {
                if (detail instanceof Array) {
                    detail = detail.filter(item => {
                        return item
                    })
                }
                // console.log(detail)

                let l = []
                detail.forEach(item => {
                    this.allBusInfor.forEach(b => {
                        if (item.vehicleId == b.actualVehicleId) {
                            l.push({
                                vehicleId: item.vehicleId,
                                vehicleNo: b.actualVehicleNo,
                                siteId: item.siteId,
                                arriveTime: item.arriveTime,
                                lat: item.lat,
                                lng: item.lng
                            })
                        }
                    })
                })
                this.busList = l || []
                this.$emit('updateBusList',this.busList)
                // console.log(this.busList)
                this.mergeStaBus()
            })
        },
        mergeStaBus() {
            let list = this.allStationList.slice(0)
            list.forEach(item => {
                if (item.bus) {
                    delete item.bus
                }
                item.bus = []
            })
            let bus = this.busList
            console.log(bus)
            bus.forEach(one => {
                // one.percen = one.isArrial == 1 ? 100 : 50
                one.percen = 100
                one.oldVehicleNo = one.vehicleNo
                // one.vehicleNo = one.oldVehicleNo.substring(2)
                one.vehicleNo = one.oldVehicleNo
                list.forEach(item => {
                    if (one.siteId == item.siteId) {
                        // if (one.isArrial == 1) {
                        // item.bus = one
                        item.bus.push(one)
                        // } else {
                        //     list[key + 1].bus = one
                        // }
                    }
                })
            })
            this.$set(this, 'allStationList', list)
        },
        firstSta() {
            if (this.upList.length && this.downList.length) {
                let upText = this.upList[0].name
                let downText = this.downList[this.downList.length - 1].name
                this.$emit('firstStaName', upText, downText)
            }
        }
    }
}

</script>
<style type="text/css" scoped="line_msg" lang="scss">
.line_msg {
    padding: 20px;
    width: auto;
    height: 320px;

    .line_up {
        left: 0px;
        white-space: nowrap;
        width: auto;
    }

    .line_down {
        right: 0px;
        white-space: nowrap;
    }
}

</style>
